export const lang = {
  headerMenus:[
      {id:0,name:"HOME",className:"header-en-0",pageSrc:"/",childrens:[]},
      {id:1,name:"ABOUT NUOXING",className:"header-en-1",childrens:[
        {id:8,name:"INTRODUCTION",className:"",pageSrc:"/about/company",childrens:[
          {id:81,name:'VALUE PROPOSITION'},
          {id:82,name:'SERVICES'},
          {id:83,name:'STRATEGIC COOPERATION'},
          {id:83,name:'STRATEGIC LAYOUT'},
        ]},
        {id:9,name:"CORPORATE CULTURE",className:"",pageSrc:"/about/culture"},
        {id:11,name:"COOPERATION UNITS",className:"",pageSrc:"/about/cooperate"}
      ]},
      {id:2,name:"FIVE-IN-ONE",className:"header-en-2",childrens:[
        {id:21,name:"SURVEY",className:"",pageSrc:"/fiveinone/generalize"},
        {id:22,name:"BIOTECHNOLOGY",className:"",pageSrc:"/fiveinone/biotechnology"},
        {id:23,name:"MEDICAL ENGINEERING",className:"",pageSrc:"/fiveinone/medicalEngineering"},
        {id:24,name:"INTELLECTUAL PROPERTY",className:"",pageSrc:"/fiveinone/intellectualProperty"},
        {id:25,name:"INT RESEARCH",className:"",pageSrc:"/fiveinone/internationalResearch"},
        {id:26,name:"INT PUBLISHING",className:"",pageSrc:"/fiveinone/internationalCopyright"}]},
      {id:3,name:"COOPERATION MODE",className:"header-en-3",childrens:[
          {id:31,name:"PROJECTS",className:"",pageSrc:"/coop/project",childrens:[
            {id:311,name:"BIOTECHNOLOGY"},
            {id:312,name:"MEDICAL ENGINEERING"},
            {id:313,name:"INT PUBLISHING"},
            {id:314,name:"INTELLECTUAL PROPERTY"},
            {id:315,name:"INT RESEARCH"}
        ]},
          {id:32,name:"COOPERATE METHOD",className:"",pageSrc:"/coop/way",childrens:[
            {id:321,name:"PERSONAL MODE"},
            {id:322,name:"LAB MODE"},
            {id:323,name:"HOSPITAL MODE"}
        ]}
      ]},
      {id:4,name:"NEWS",className:"header-en-4",childrens:[
        {id:41,name:"PROFESSION",className:"",pageSrc:"/news/industry"},
        {id:42,name:"COMPANY",className:"",pageSrc:"/news/company"},
        {id:43,name:"STAFF",className:"",pageSrc:"/news/staff"}
      ]},
      {id:5,name:"CORE MEMBERS",className:"header-en-5",pageSrc:"/coreperson",childrens:[]},
      {id:6,name:"NUOXING U LEARNING",className:"header-en-6",childrens:[
        {id:61,name:'Domestic topics',pageSrc:"/study"},
            {id:62,name:'Foreign topics',pageSrc:"/studygw"},
      ]},
      {id:7,name:"JOIN US",className:"header-en-7",pageSrc:"/joinNorxin",childrens:[]},
      {id:8,name:"CONTACT",className:"header-en-8",pageSrc:"/contactus",childrens:[]},
    ],
    button:{
      one:'MORE'
    },
    info:{
      address:"Address: Room 1503, building 2, East China wanyuecheng, Changle West Road, Xincheng District, Xi'an City, Shaanxi Province",
      email:'E-mail: nuoxingyouchuang@nxyc.site',
      tel:'Tel: +86-029-83643680',
      copyright:'Copyright: Shaanxi nuoxing Enterprise Consulting Management Co., Ltd',
      icp:'陕ICP备0000000号-1',
    },
}