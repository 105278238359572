<template>
  <div class="shanxinuoxingyouchuang-os">
    <!-- 顶部菜单导航 -->
    <div class="header"  v-show="$i18n.locale=='zh'">
      <el-row>
        <el-col :span="5">
           <a class="headerLogo" href="/"></a>
        </el-col>
        <el-col :span="19">
            <ul class="headerList">
              <li :class="'header-li '+item.className" v-for="(item,index) in headerMenus" :key="index" @click="selectHeader(index)" @mouseenter="StopRoll(index)" @mouseleave="UpRoll()">
                <a :class="[currIndex==index?'on':'']"><span v-html="item.name"></span><!--<span class="headerTitleBg"></span>--></a>
              </li>
              <li class="headerSerach">
                 <div class="search-input">
                   <i class="el-icon-search"></i>
                   <input type="text" v-model="searchStr" placeholder="" @change="searchChange"/>
                 </div> 
              </li>
              <li>
                 <div class="search-langs">
                  <el-dropdown @command="handleCommand">
                    <span class="el-dropdown-link">
                      中/EN<i class="el-icon-arrow-down el-icon--right"></i>
                    </span>
                    <template slot="dropdown">
                      <el-dropdown-menu>
                        <el-dropdown-item command="zh">中文</el-dropdown-item>
                        <el-dropdown-item command="en">EN</el-dropdown-item>
                      </el-dropdown-menu>
                    </template>
                  </el-dropdown>
                 </div>
              </li>
            </ul>
            <div :class="'nuox-nav '+[navChildren?'nuox-nav-show':'']" v-if="navChildren">
                <div :class="'nuox-nav-list '+item.className" v-for="(item,index1) in headerMenus" :key="index1" @mouseenter="StopRoll(index1)" @mouseleave="UpChildrenRoll()">
                  <dl class="nuox-nav-child" v-if="item.childrens&&item.childrens.length>0&&currIndex==index1">
                      <dd :class="[currIndexChildrenId==index2?'nuox-check':'']" v-for="(item2,index2) in item.childrens" :key="index2" @click="selectHeaderChild(item2,index1,index2)">{{item2.name}}</dd>
                  </dl>
                </div>
            </div>
        </el-col>
      </el-row>
    </div>
    <!-- 英文头 -->
    <div class="header header-en"  v-show="$i18n.locale=='en'">
        <el-row>
        <el-col :span="5">
           <a class="headerLogo" href="/"></a>
        </el-col>
         <el-col :span="19">
            <ul class="headerList">
              <li :class="'header-li '+item.className" v-for="(item,index) in headerMenus" :key="index" @click="selectHeader(index)" @mouseenter="StopRoll(index)" @mouseleave="UpRoll()">
                <a :class="[currIndex==index?'on':'']"><span v-html="item.name"></span><span class="headerTitleBg"></span></a>
              </li>
              <li class="headerSerach">
                 <div class="search-input">
                   <i class="el-icon-search"></i>
                   <input type="text" placeholder="" v-model="searchStr" @change="searchChange"/>
                 </div> 
              </li>
              <li>
                 <div class="search-langs">
                  <el-dropdown @command="handleCommand">
                    <span class="el-dropdown-link">
                      中/EN<i class="el-icon-arrow-down el-icon--right"></i>
                    </span>
                    <template slot="dropdown">
                      <el-dropdown-menu>
                        <el-dropdown-item command="zh">中文</el-dropdown-item>
                        <el-dropdown-item command="en">EN</el-dropdown-item>
                      </el-dropdown-menu>
                    </template>
                  </el-dropdown>
                 </div>
              </li>
            </ul>
            <div :class="'nuox-nav '+[navChildren?'nuox-nav-show':'']" v-if="navChildren">
                <div :class="'nuox-nav-list '+item.className" v-for="(item,index1) in headerMenus" :key="index1" @mouseenter="StopRoll(index1)" @mouseleave="UpChildrenRoll()">
                  <dl class="nuox-nav-child" v-if="item.childrens&&item.childrens.length>0&&currIndex==index1">
                      <dd :class="[currIndexChildrenId==index2?'nuox-check':'']" v-for="(item2,index2) in item.childrens" :key="index2" @click="selectHeaderChild(item2,index1,index2)">{{item2.name}}</dd>
                  </dl>
                </div>
            </div>
            </el-col>
          </el-row>
    </div>
    <!-- 内容区 -->
    <router-view/>
      <!-- 底部信息 -->
      <footer class="nuox-footer">
        <div class="nuox-footer-nav">
          <span v-for="(item,index3) in headerMenus" :key="index3" @click="selectHeader(index3)">
            <el-divider direction="vertical" v-if="index3>0"></el-divider>
            <span>{{item.name}}</span> 
          </span>
        </div>
        <div class="nuox-footer-info">
          <span v-if="$i18n.locale=='zh'">电话：{{relationOne.phone}}</span>
          <span v-if="$i18n.locale=='en'">Tel: {{relationOne.phone}}</span>
          <span v-if="$i18n.locale=='zh'">邮箱：{{relationOne.email}}</span>
          <span v-if="$i18n.locale=='en'">E-mail: {{relationOne.email}}</span>
          <span v-if="$i18n.locale=='zh'">地址：{{relationOne.addressZh}}</span>
          <span v-if="$i18n.locale=='en'">Add.: {{relationOne.addressEn}}</span>
          <span v-if="$i18n.locale=='zh'"><a class="nuox-beian" href="http://www.norxin-publisher.com/" target="_blank">新加坡诺兴出版社</a></span>
          <span v-if="$i18n.locale=='en'"><a class="nuox-beian" href="http://www.norxin-publisher.com/" target="_blank">Singapore-norxin</a></span>
        </div>
        <div class="nuox-footer-copyright  bg-gray">
          <span v-if="$i18n.locale=='en'">Copyright: {{relationOne.copyrightEn}}</span>
          <span v-if="$i18n.locale=='zh'">版权：{{relationOne.copyrightZh}}</span>
          <span v-if="$i18n.locale=='en'"><a class="nuox-beian" href="http://beian.miit.gov.cn/" target="_blank">{{relationOne.icpEn}}</a></span>
          <span v-if="$i18n.locale=='zh'"><a class="nuox-beian" href="http://beian.miit.gov.cn/" target="_blank">{{relationOne.icpZh}}</a></span>
        </div>
      </footer>
     <el-backtop target=".shanxinuoxingyouchuang-os" :bottom="100">
        <div
          style="{
            height: 100%;
            width: 100%;
            background-color: #f2f5f6;
            box-shadow: 0 0 6px rgba(0,0,0, .12);
            text-align: center;
            line-height: 40px;
            color: #1989fa;
          }"
        >
          <i class="el-icon-caret-top"></i>
        </div>
      </el-backtop>
  </div>
</template>
<script>
export default {
  computed:{
    headerMenus:function(){
      return this.$t('lang.headerMenus');
    }
  },
  data:function() {
  return {
        currIndex:0,
        currItemId:0,
        currIndexChildrenId:-1,
        currItemChildrenId:-1,
        navChildren:false,
        searchStr:'',
        relationOne:{
          phone:'',email:'',addressEn:'',addressZh:'',copyrightEn:'',copyrightZh:'',icpEn:'',icpZh:''
        }
    };
  },
  mounted(){
    this.relationHome();
    var path = window.document.location.pathname;
    var menus  = this.headerMenus;
    if(localStorage.getItem('locale')){
        this.$i18n.locale = localStorage.getItem('locale');
    }else{
      localStorage.setItem('locale',this.$i18n.locale);
    }
    this.menusCheck(menus,path,0,'father');
  },
  watch:{
    $route(to,from){
       this.menusCheck(this.headerMenus,to.path,0);
    }
  },
  components: {
  },
  methods:{
    menusCheck(menus,path,index,type){
      for(var k=0;k<menus.length;k++){
         if(menus[k].pageSrc){
            if(menus[k].pageSrc==path){
              if(type=='son'){
                this.currIndex = index; 
                this.currItemId = index; 
                this.currIndexChildrenId = k;
                this.currItemChildrenId = k;
              }else{
                this.currIndex = k; 
                this.currItemId = k; 
              }
              return;
            }
          }else  if(menus[k].childrens&&menus[k].childrens.length>0){
              this.menusCheck(menus[k].childrens,path,k,'son');
          }
      }
    },
    selectHeader(index){
        this.currIndex = index;
        this.currItemId = index;
        let pageSrc = this.headerMenus[index].pageSrc;
        if(pageSrc&&this.headerMenus[index].childrens.length==0){
          this.$router.push({ 
            path:pageSrc,
            query:{}
          })
        }else if(this.headerMenus[index].childrens&&this.headerMenus[index].childrens.length>0){
          var pageS = this.headerMenus[index].childrens[0].pageSrc;
          this.currIndexChildrenId = 0;
          this.currItemChildrenId = 0;
          this.$router.push({ 
            path:pageS,
            query:{}
          })
        }
    },
    StopRoll(index){
        /*if(this.currItemId==index){//已选中父节点后，不在展开子节点
          this.navChildren=false;
          return;
        }*/
        this.currIndex = index;
        let childrens = this.headerMenus[index].childrens;
          if(childrens.length>0){
            if(this.currItemId != index){
              this.currIndexChildrenId = -1;
            }else{
              this.currIndexChildrenId = this.currItemChildrenId;
            }
            this.navChildren=true;
          }else{
            this.navChildren=false;
          }
    },
    UpRoll(){
        this.currIndex = this.currItemId;
    },
    UpChildrenRoll(){
        this.currIndex = this.currItemId;
        this.navChildren=false;
    },
    handleClick(tab, event) {
        console.log(tab, event);
    },
    selectHeaderChild(e,parentIndex,index){
      this.currIndex = parentIndex;
      this.currItemId = parentIndex;
      this.currItemChildrenId = index;
      this.currIndexChildrenId = index;
      if(e.pageSrc){
          this.$router.push({ 
            path: e.pageSrc,
            query: {time:new Date().getTime()}
          })
      }
    },
    handleCommand(command){
      localStorage.setItem('locale', command)
      this.$i18n.locale = command;
      window.location.reload();
    },
    searchChange(){//搜索方法
      var searchStr = this.searchStr;
      if(searchStr==""){
        this.$router.push({ 
                    path: '/'
        })
      }else{
        this.$router.push({ 
                    path: '/search',
                    query: {key:searchStr}
        })
      }
      
    },
    async relationHome(){//加载公司信息
      var that = this;
       var baseUrl = this.$baseURL;
      const { data: res } = await this.$http.get(
        "webSite/nuox_relation?pageNum=1&pageCount=2"
      );
      if(res.code==0){
        var datas = res.data.data;
        if(datas.length>0){
          var data = datas[0];
          var imgFm = '';
          var imgQrcode = '';
          var fmAttList = data.fmAttList;
          var ewmAttList = data.ewmAttList;
          for(var index in fmAttList){
            imgFm=baseUrl+fmAttList[index].attPath+fmAttList[index].attRealName;
            break;
          }
           for(var index in ewmAttList){
            imgQrcode=baseUrl+ewmAttList[index].attPath+ewmAttList[index].attRealName;
            break;
          }
          that.relationOne.nameZh = data.relationNameZh;
          that.relationOne.nameEn = data.relationNameEn;
          that.relationOne.addressZh = data.relationAddressZh;
          that.relationOne.addressEn = data.relationAddressEn;
          that.relationOne.phone = data.relationPhone;
          that.relationOne.email = data.relationEmail;
          that.relationOne.copyrightZh = data.relationInformationZh;
          that.relationOne.copyrightEn = data.relationInformationEn;
          that.relationOne.icpZh = data.relationNo;
          that.relationOne.icpEn = data.relationNo;
        }
      }
    },
  }
}
</script>
<style>
@import './assets/css/index.css';
@import './assets/font/DINfont.css';
@import '//at.alicdn.com/t/font_2602563_hx5z3akskqm.css';
.nuox-beian{
  text-decoration: none;
  color: #333333;
}
.nuox-beian:hover{
  color: #000000;
}
</style>
