import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import ElementUI from 'element-ui';
import axios from 'axios'
import 'element-ui/lib/theme-chalk/index.css';
import ElementLocale from 'element-ui/lib/locale'
import elementEnLocale from 'element-ui/lib/locale/lang/en'  // elementui 的英文语言包
import elementZhLocale from 'element-ui/lib/locale/lang/zh-CN' // elementui 的中文语言包
import moment from 'moment'//导入文件
Vue.prototype.$moment = moment;//赋值使用
import VueI18n from 'vue-i18n';
Vue.use(VueI18n);
import echarts from 'echarts'
import VideoPlayer from 'vue-video-player'
require('video.js/dist/video-js.css')
require('vue-video-player/src/custom-theme.css')

Vue.use(VideoPlayer)
Vue.prototype.$echarts = echarts 
Vue.use(ElementUI);
var baseURL = "http://39.104.95.82:8087";
//var baseURL = "http://localhost:8087/";
var token = "";
Vue.prototype.$baseURL = baseURL;
axios.defaults.baseURL = baseURL;
axios.interceptors.request.use(config => {
    config.headers.Authorization = token;
    return config;
});

Vue.prototype.$http = axios;

Vue.prototype.$getHtmlToText = function(html){
    if(html){
      var re1 = new RegExp("<.+?>","g");//匹配html标签的正则表达式，"g"是搜索匹配多个符合的内容
      var value = html.replace(re1,'');//执行替换成空字符
      value = value.replace(/(\n)/g, "");  
      value = value.replace(/(\t)/g, "");  
      value = value.replace(/(\r)/g, "");  
      value = value.replace(/<\/?[^>]*>/g, "");  
      value = value.replace(/\s*/g, "");
      return value;
    }else{
      return "";
    }
}

const i18n = new VueI18n({
  locale: 'zh',
  messages: {
    'zh': {...require('@/assets/lang/zh.js'),...elementZhLocale},
    'en': {...require('@/assets/lang/en.js'),...elementEnLocale},
  }
});
ElementLocale.i18n((key, value) => i18n.t(key, value))
Vue.config.productionTip = false

router.afterEach((to,from,next) => {
  console.log("kakka");
  window.scrollTo(0,0);
})

new Vue({
  router,
  store,
  i18n,
  render: h => h(App)
}).$mount('#app')
