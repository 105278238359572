export const lang = {
    headerMenus:[
        {id:0,name:"首页",className:"header-first",pageSrc:"/",childrens:[]},
        {id:1,name:"关于诺兴",className:"",childrens:[
          {id:8,name:"公司介绍",className:"",pageSrc:"/about/company",childrens:[
            {id:81,name:'价值主张'},
            {id:82,name:'服务板块'},
            {id:83,name:'战略合作'},
            {id:84,name:'战略布局'},
          ]},
          {id:9,name:"企业文化",className:"",pageSrc:"/about/culture"},
          {id:11,name:"合作单位",className:"",pageSrc:"/about/cooperate"}
        ]},
        {id:2,name:"五位一体",className:"",childrens:[
          {id:21,name:"概\u00a0\u00a0况",className:"",pageSrc:"/fiveinone/generalize"},
          {id:22,name:"生物科技",className:"",pageSrc:"/fiveinone/biotechnology"},
          {id:23,name:"医药工程",className:"",pageSrc:"/fiveinone/medicalEngineering"},
          {id:24,name:"知识产权",className:"",pageSrc:"/fiveinone/intellectualProperty"},
          {id:25,name:"国际科研",className:"",pageSrc:"/fiveinone/internationalResearch"},
          {id:26,name:"国际出版",className:"",pageSrc:"/fiveinone/internationalCopyright"}]},
        {id:3,name:"合作模式",className:"",childrens:[
            {id:31,name:"项目板块",className:"",pageSrc:"/coop/project",childrens:[
              {id:311,name:"生物科技"},
              {id:312,name:"医药工程"},
              {id:313,name:"国际出版"},
              {id:314,name:"知识产权"},
              {id:315,name:"国际科研"}
          ]},
            {id:32,name:"合作方式",className:"",pageSrc:"/coop/way",childrens:[
              {id:321,name:"个人模式"},
              {id:322,name:"实验室模式"},
              {id:323,name:"医院模式"}
          ]}
        ]},
        {id:4,name:"新闻资讯",className:"",childrens:[
          {id:41,name:"行业新闻",className:"",pageSrc:"/news/industry"},
          {id:42,name:"公司动态",className:"",pageSrc:"/news/company"},
          {id:43,name:"员工风采",className:"",pageSrc:"/news/staff"}
        ]},
        {id:5,name:"核心人员",className:"",pageSrc:"/coreperson",childrens:[]},
        {id:6,name:"诺兴优学",className:"",childrens:[
          {id:61,name:'国内专题',pageSrc:"/study"},
          {id:62,name:'国外专题',pageSrc:"/studygw"}]},
        {id:7,name:"加入诺兴",className:"",pageSrc:"/joinNorxin",childrens:[]},
        {id:8,name:"联系我们",className:"",pageSrc:"/contactus",childrens:[]},
      ],
      button:{
        one:'了解更多'
      },info:{
        address:'地址：陕西省西安市新城区长乐西路华东万悦城2号楼 1503室',
        email:'邮箱：nuoxingyouchuang@nxyc.site',
        tel:'电话：+86-029-83643680',
        copyright:'版权：陕西诺兴企业咨询管理有限公司',
        icp:'陕ICP备0000000号-1',
      },
}