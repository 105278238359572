import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/Home.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home
  },
  {
    path: '/contactus',
    name: 'contactus',
    component: () => import('../views/contactUS.vue')
  },
  {
    path: '/study',
    name: 'study',
    component: () => import('../views/study.vue')
  },
  {
    path: '/studygw',
    name: 'studygw',
    component: () => import('../views/studyGw.vue')
  },
  {
    path: '/coreperson',
    name: 'corePerson',
    component: () => import('../views/corePerson.vue')
  },
  {
    path: '/about/company',
    name: 'company',
    component: () => import('../views/about/company.vue')
  },
  {
    path: '/about/cooperate',
    name: 'cooperate',
    component: () => import('../views/about/cooperate.vue')
  },
  {
    path: '/about/culture',
    name: 'culture',
    component: () => import('../views/about/culture.vue')
  },
  {
    path: '/about/layout',
    name: 'layout',
    component: () => import('../views/about/layout.vue')
  },{
    path: '/fiveinone/generalize',
    name: 'generalize',
    component: () => import('../views/fiveinone/generalize.vue')
  },
  {
    path: '/fiveinone/biotechnology',
    name: 'biotechnology',
    component: () => import('../views/fiveinone/biotechnology.vue')
  },
  {
    path: '/fiveinone/intellectualProperty',
    name: 'intellectualProperty',
    component: () => import('../views/fiveinone/intellectualProperty.vue')
  },
  {
    path: '/fiveinone/internationalCopyright',
    name: 'internationalCopyright',
    component: () => import('../views/fiveinone/internationalCopyright.vue')
  },
  {
    path: '/fiveinone/internationalResearch',
    name: 'internationalResearch',
    component: () => import('../views/fiveinone/internationalResearch.vue')
  },
  {
    path: '/fiveinone/medicalEngineering',
    name: 'medicalEngineering',
    component: () => import('../views/fiveinone/medicalEngineering.vue')
  },
  {
    path: '/coop/project',
    name: 'coopProject',
    component: () => import('../views/co-op/project.vue')
  },
  {
    path: '/coop/way',
    name: 'coopWay',
    component: () => import('../views/co-op/way.vue')
  },
  {
    path: '/news/company',
    name: 'newsCompany',
    component: () => import('../views/news/company.vue')
  },
  {
    path: '/news/industry',
    name: 'newsIndustry',
    component: () => import('../views/news/industry.vue')
  },
  {
    path: '/news/staff',
    name: 'newsStaff',
    component: () => import('../views/news/staff.vue')
  },
  {
    path: '/joinNorxin',
    name: 'joinNorxin',
    component: () => import('../views/joinNorxin.vue')
  },
  {
    path: '/search',
    name: 'search',
    component: () => import('../views/search.vue')
  },
]

const router = new VueRouter({
  mode: 'hash',
  base: process.env.BASE_URL,
  routes
})
const VueRouterPush = VueRouter.prototype.push
VueRouter.prototype.push = function push (to) {
  return VueRouterPush.call(this, to).catch(err => err)
}

export default router
