<template>
  <div>
    <!-- 首页轮播图 -->
    <div class="swiper-container" style="height:auto;min-height: 400px;"  v-loading="loading">
        <div class="swiper-wrapper">
            <div class="swiper-slide" v-for="(item,index) in carouselImgs" :title="item.link" :key="index" @click="swiperClick(item)">
              <img class="swiper-img"  :src="item.imgSrc">
            </div>
        </div>
        <div class="nuox-swiper-button-prev">
          <i class="el-icon-caret-left"></i>
        </div>
        <div class="nuox-swiper-button-next">
          <i class="el-icon-caret-right"></i>
        </div>
        <div class="swiper-pagination"></div>
    </div>
  
  <!-- 新闻动态 -->
  <div>
    <div class="nuox-content-title"></div>
    <div class="nuox-content">
      <div class="nuox-news">
          <el-row :gutter="10">
            <el-col :span="12">
                <el-image  style="width: 100%; height: 540px" :src="zhanshiImgs[0]?zhanshiImgs[0].imgSrc:''" :title="zhanshiImgs[0]?zhanshiImgs[0].link:''" @click="swiperClick(zhanshiImgs[0])" fit="cover">
                  <div slot="error" class="image-slot">
                    <i class="el-icon-picture-outline"></i>
                  </div>
                </el-image>
            </el-col>
            <el-col :span="12">
              <el-image  style="width: 100%; height: 210px" :src="zhanshiImgs[1]?zhanshiImgs[1].imgSrc:''" :title="zhanshiImgs[0]?zhanshiImgs[1].link:''" @click="swiperClick(zhanshiImgs[1])" fit="cover">
                  <div slot="error" class="image-slot">
                    <i class="el-icon-picture-outline"></i>
                  </div>
              </el-image>
              <el-image  style="width: 100%; height: 321px;margin-top: 5px;" :src="zhanshiImgs[2]?zhanshiImgs[2].imgSrc:''"  :title="zhanshiImgs[0]?zhanshiImgs[2].link:''" @click="swiperClick(zhanshiImgs[2])" fit="cover">
                <div slot="error" class="image-slot">
                    <i class="el-icon-picture-outline"></i>
                  </div>
              </el-image>
            </el-col>
          </el-row>
      </div>
      <div class="nuox-news-list">
          <div style="margin-left: 30px;">
          <div style="border-bottom: 2px solid #FFBC00; padding-bottom: 20px; ">
              <span v-if="activeName=='first'" class="nx-title nx-tab-check" @click="tabTitleClick('first')"><i class="el-icon-arrow-right"></i>{{$i18n.locale=='zh'?'行业新闻':'PROFESSION'}}<i class="el-icon-arrow-left"></i></span>
              <span v-else class="nx-title" @click="tabTitleClick('first')">{{$i18n.locale=='zh'?'行业新闻':'PROFESSION'}}</span>
              <span v-if="activeName=='second'" style="margin-left: 5px;" class="nx-title nx-tab-check" @click="tabTitleClick('second')"><i class="el-icon-arrow-right"></i>{{$i18n.locale=='zh'?'员工风采':'STAFF'}}<i class="el-icon-arrow-left"></i></span>
              <span v-else class="nx-title"  style="margin-left: 5px;" @click="tabTitleClick('second')">{{$i18n.locale=='zh'?'员工风采':'STAFF'}}</span>
          </div>
          <el-tabs v-model="activeName" @tab-click="handleClick">
            <el-tab-pane  name="first">
                  <div v-for="(item,index) in newsTwo" :key="index" @click="seeNews(item.id,'news/industry')" @mouseenter="industryMouseenter(index)" @mouseleave="newMouseleave()" :class="'industry_list '+(industryIndex==index?'nx-n-check':'')" >
                    <el-row type="flex" class="row-bg">
                      <el-col :span="5">
                          <div class="nx-n-date">
                             <div class="nx-n-day">{{item.day}}</div>
                              <div class="nx-n-ym">{{item.yearMonth}}</div>
                          </div>
                      </el-col>
                      <el-col :span="20">
                          <div v-if="$i18n.locale=='zh'" class="nx-n-title"  v-html="item.titleZh"></div>
                          <div v-if="$i18n.locale=='en'" class="nx-n-title"  v-html="item.titleEn"></div>
                          <div v-if="$i18n.locale=='zh'" class="nx-n-content"  v-html="item.contentZh"></div>
                          <div v-if="$i18n.locale=='en'" class="nx-n-content" v-html="item.contentEn"></div>
                      </el-col>
                    </el-row>
                    <div class="nx-n-right" v-if="industryIndex!=index">
                      <div class="nx-n-icon">
                          <i class="el-icon-right"></i>
                      </div>
                    </div>
                  </div>
            </el-tab-pane>
            <el-tab-pane  name="second">
              <div v-for="(item,index) in newsThree" :key="index" @click="seeNews(item.id,'news/staff')" @mouseenter="staffMouseenter(index)" @mouseleave="newMouseleave()" :class="'industry_list '+(staffIndex==index?'nx-n-check':'')" >
                    <el-row type="flex" class="row-bg">
                      <el-col :span="5">
                          <div class="nx-n-date">
                             <div class="nx-n-day">{{item.day}}</div>
                              <div class="nx-n-ym">{{item.yearMonth}}</div>
                          </div>
                      </el-col>
                      <el-col :span="20">
                          <div v-if="$i18n.locale=='zh'" class="nx-n-title"  v-html="item.titleZh"></div>
                          <div v-if="$i18n.locale=='en'" class="nx-n-title"  v-html="item.titleEn"></div>
                          <div v-if="$i18n.locale=='zh'" class="nx-n-content"  v-html="item.contentZh"></div>
                          <div v-if="$i18n.locale=='en'" class="nx-n-content" v-html="item.contentEn"></div>
                      </el-col>
                    </el-row>
                  </div>
            </el-tab-pane>
          </el-tabs>
           </div>
      </div>
    </div>
  </div>
  <!-- 五位一体 -->
  <div class="nuox-wwyt">
    <div class="nuox-wwyt-bg"></div>
    <div class="nuox-wwyt-content">
      <div class="nuox-content-title">
        <div class="title-name" style="font-size: 25px;">
          {{$i18n.locale=='zh'?'五位一体':'FIVE-IN-ONE'}}
          <div class="xian"></div>
        </div>
      </div>
      <div class="nuox-wwyt-module" >
        <img  v-for="(item,index) in wwytImgs" :key="index"  @click="selectFive(item.link)" :src="item.imgSrc">
      </div>
    </div>
  </div>
 
  </div>
</template>

<script>
import Swiper from "swiper/swiper-bundle.min.js"
import "swiper/swiper-bundle.min.css"
var that,baseUrl;
export default {
  name: 'Home',
  data:function() {
  return {
        carouselImgs:[],
        zhanshiImgs:[{link:'',imgSrc:''},{link:'',imgSrc:''},{link:'',imgSrc:''}],
        wwytImgs:[],
        news:[],
        newsTwo:[],
        newsThree:[],
        currNews:{},
        activeName: 'first',
        pageNum:1,
        pageCount:3,
        loading:true,
        industryIndex:0,
        staffIndex:0,
    };
  },
  mounted(){
    that = this;
    baseUrl = this.$baseURL;
    this.init();
    this.initZST();
    this.initWwyt();
    this.initNews();
    this.initNewsTwo();
    this.initNewsThree();
  },
  components: {
  },
  methods:{
    async init(){//初始加载轮播图 localStorage.getItem('locale')
      var locale =  localStorage.getItem('locale');
      const { data: res } = await this.$http.get(
        "webSite/get_home_img?type="+locale+"&homeType=0"
      );
      if(res.code==0){
        var carouselImgs = [];
        var data = res.data.data;
        for(var key in data){
          if(data[key].attList&&data[key].attList.length>0&&data[key].isShow==1){
              carouselImgs.push({imgSrc:baseUrl+data[key].attList[0].attPath+data[key].attList[0].attRealName,id:data[key].homePageId,link:data[key].link});
          }
        }
        that.carouselImgs = carouselImgs;
      }
       setTimeout(() =>{
           new Swiper(".swiper-container",{
            loop:true, //循环
            speed: 400,
            autoplay: true,
            pagination: { //分页符
                el: '.swiper-pagination',
            },
            navigation: {// 如果需要前进后退按钮
              nextEl: '.nuox-swiper-button-next',
              prevEl: '.nuox-swiper-button-prev',
            },
        })
        that.loading=false;
        },1000);
    },
    async initZST(){//初始加载展示图 localStorage.getItem('locale')
      var locale =  localStorage.getItem('locale');
      const { data: res } = await this.$http.get(
        "webSite/get_home_img?type="+locale+"&homeType=1"
      );
      if(res.code==0){
        var zhanshiImgs = [];
        var data = res.data.data;
        for(var key in data){
          if(data[key].attList&&data[key].attList.length>0&&data[key].isShow==1){
              zhanshiImgs.push({imgSrc:baseUrl+data[key].attList[0].attPath+data[key].attList[0].attRealName,id:data[key].homePageId,link:data[key].link});
          }
        }
        if(that.zhanshiImgs.length>0){
               that.zhanshiImgs = zhanshiImgs;
        }
      }
    },
    async initWwyt(){//加载五位一体图
      var locale =  localStorage.getItem('locale');
      const { data: res } = await this.$http.get(
        "webSite/get_home_img?type="+locale+"&homeType=2"
      );
      if(res.code==0){
        var wwytImgs = [];
        var data = res.data.data;
        for(var key in data){
          if(data[key].attList&&data[key].attList.length>0&&data[key].isShow==1&&key<5){
              wwytImgs.push({imgSrc:baseUrl+data[key].attList[0].attPath+data[key].attList[0].attRealName,id:data[key].homePageId,link:data[key].link});
          }
        }
        that.wwytImgs = wwytImgs;
      }
    },
    async initNews(){//初始加载新闻
      var locale =  this.$i18n.locale;
      var pageNum = this.pageNum;
      var pageCount = this.pageCount;
      var that = this;
      const { data: res } = await this.$http.get(
        "webSite/new_dynamic?pageNum="+pageNum+"&pageCount="+pageCount
      );
      if(res.code==0){
        var data = res.data.data
        var news =[]
        for(var key in data){
          var new1 = {};
          if(data[key].attList.length>0){
              new1.imgSrc = baseUrl+data[key].attList[0].attPath+data[key].attList[0].attRealName;
          }
          new1.contentEn=that.$getHtmlToText(data[key].abstractContentEn);
          new1.contentZh=that.$getHtmlToText(data[key].abstractContentZh);
          new1.titleEn=data[key].dynamicTitleEn;
          new1.titleZh=data[key].dynamicTitleZh;
          new1.date=data[key].dynamicDate;
          new1.id=data[key].dynamicId;
          news.push(new1);
        }
        that.news = news;
        if(news.length>0){
          that.currNews=news[0];
        }
      }
    },
    async initNewsTwo(){//初始行业
      var locale =  this.$i18n.locale;
      var pageNum = this.pageNum;
      var pageCount = this.pageCount;
      var that = this;
      const { data: res } = await this.$http.get(
        "webSite/new_industry?pageNum="+pageNum+"&pageCount="+pageCount
      );
      if(res.code==0){
        var data = res.data.data;
        var newsTwo =[]
        for(var key in data){
          var new1 = {};
          if(data[key].attList.length>0){
              new1.imgSrc = baseUrl+data[key].attList[0].attPath+data[key].attList[0].attRealName;
          }
          new1.contentEn=that.$getHtmlToText(data[key].abstractContentEn);
          new1.contentZh=that.$getHtmlToText(data[key].abstractContentZh);
          new1.titleEn=data[key].industryTitleEn;
          new1.titleZh=data[key].industryTitleZh;
          var date = data[key].industryDate.split("-");
          if(date.length==3){
              new1.yearMonth=date[0]+"."+date[1];
              new1.day=date[2];
          }
          new1.date=data[key].industryDate;
          new1.id=data[key].industryId;
          newsTwo.push(new1);
        }
        that.newsTwo = newsTwo;
      }
    },
    async initNewsThree(){//初始员工风采
      var locale =  this.$i18n.locale;
      var pageNum = this.pageNum;
      var pageCount = this.pageCount;
      var that = this;
      const { data: res } = await this.$http.get(
        "webSite/new_personnel?pageNum="+pageNum+"&pageCount="+pageCount
      );
      if(res.code==0){
        var data = res.data.data;
        var newsThree =[]
        for(var key in data){
          var new1 = {};
          if(data[key].attList.length>0){
              new1.imgSrc = baseUrl+data[key].attList[0].attPath+data[key].attList[0].attRealName;
          }
          new1.contentEn=that.$getHtmlToText(data[key].abstractContentEn);
          new1.contentZh=that.$getHtmlToText(data[key].abstractContentZh);
          new1.titleEn=data[key].personnelNameEn;
          new1.titleZh=data[key].personnelNameZh;
          var date = data[key].personnelDate.split("-");
          if(date.length==3){
              new1.yearMonth=date[0]+"."+date[1];
              new1.day=date[2];
          }
          new1.date=data[key].personnelDate;
          new1.id=data[key].personnelId;
          newsThree.push(new1);
        }
        that.newsThree = newsThree;
      }
    },
    seeNews(id,model){
      this.$router.push({ 
            path: model,
            query: {id:id}
      })
    },
    selectHeader(index){
        this.currIndex = index;
        this.currItemId = index;
    },
    StopRoll(index){
        this.currIndex = index;
    },
    UpRoll(){
        this.currIndex = this.currItemId;
    },
    handleClick(tab, event) {
        console.log(tab, event);
    },
    tabTitleClick(e){
        this.activeName=e;
    },
    swiperClick(e){
      if(e.link!=""){
        window.open(e.link);
      }
    },
    selectFive(path){
       this.$router.push({ 
            path: path
          })
    },
    industryMouseenter(index){
      this.industryIndex=index;
    },
    staffMouseenter(index){
      this.staffIndex=index;
    },
    newMouseleave(){
      this.industryIndex=0;
      this.staffIndex=0;
    }
  }
}
</script>
<style>
/**轮播块*/
  .swiper-img{
      width: 100%;
      height: auto;
  }
  .swiper-slide{
    height: auto;
  }
  .swiper-pagination-bullet{
      width: 14px;
      height: 14px;
  }
  .nuox-swiper-button-next{
      right: 20px;
      left: auto;
  }
  .nuox-swiper-button-prev{
      right: auto;
      left: 20px;
  }
  .nuox-swiper-button-next, .nuox-swiper-button-prev {
      position: absolute;
      top: calc(50% - 20px);
      margin-top: calc(0px - (var(--swiper-navigation-size)/ 2));
      z-index: 10;
      cursor: pointer;
      display: flex;
      align-items: center;
      justify-content: center;
      color: #FFFFFF;
      opacity:0.5;
      font-size: 70px;
  }
  .nuox-swiper-button-next:hover, .nuox-swiper-button-prev:hover{
      opacity:1;
  }
  .swiper-pagination-bullet-active {
      background: #FFFFFF;
  }
  
</style>